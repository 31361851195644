<template>
  <moe-page title="推文详情">
    <moe-form v-if="pushData.id" :showBack="false" :showClose="true">
      <moe-describe-list title="" :col="1">
        <moe-describe-item label="推文标题"> {{ pushData.title }} </moe-describe-item>
        <moe-describe-item label="发送对象"> {{ $moe_format.getSendTargetList(pushData.sendTarget) }} </moe-describe-item>
        <moe-describe-item label="指定用户" v-if="pushData.sendTarget === 'SPECIFY_USER'">
          <div class="df aic fww">
            <moe-tag class="mr-10 mb-10" v-for="(item, index) in pushData.sendTargetData" :key="index">{{ `用户编号: ${item.id}` }}</moe-tag>
          </div>
        </moe-describe-item>
        <moe-describe-item label="指定标签用户" v-if="pushData.sendTarget === 'SPECIFY_TAG'">
          <div class="df aic fww">
            <moe-tag class="mr-10 mb-10" v-for="(item, index) in SPECIFY_USER_LIST" :key="index">{{ item }}</moe-tag>
          </div>
        </moe-describe-item>
        <moe-describe-item label="黑名单用户">
          <div class="df aic fww">
            <moe-tag class="mr-10 mb-10" v-for="(item, index) in pushData.sendExcludeData" :key="index">{{ `用户编号: ${item.id}` }}</moe-tag>
          </div>
        </moe-describe-item>
        <moe-describe-item label="消息类型"> {{ $moe_format.getPushTypeList(pushData.pushType) }} </moe-describe-item>
        <moe-describe-item label="推文图片">
          <moe-image v-if="pushData.image" :src="pushData.image" width="100px" height="100px"></moe-image>
        </moe-describe-item>
        <moe-describe-item label="推文内容"> {{ pushData.content }} </moe-describe-item>

        <moe-describe-item label="跳转页面">
          <template v-if="pushData.url"> {{ $moe_format.getNavigateToUrlList(pushData.url) }} </template>
        </moe-describe-item>

        <el-form-item v-if="pushData.url && $moe_data.navigateToIsValidator.includes(pushData.url)" label="编号">
          {{ pushData.urlId }}
        </el-form-item>

        <moe-describe-item label="发送类型"> {{ $moe_format.getPushOpportunityList(pushData.pushOpportunity) }} </moe-describe-item>

        <moe-describe-item label="发送时间"> {{ pushData.pushTime }} </moe-describe-item>
      </moe-describe-list>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'PushDetail',
  computed: {
    SPECIFY_USER_LIST() {
      const { sendTarget, sendTargetData } = this.pushData;
      if (sendTarget === 'SPECIFY_TAG') {
        return sendTargetData.split(',').map((tagId) => {
          return this.userTagList.find(({ value }) => String(value) === tagId)?.label;
        })
      }
      return []
    }
  },
  data() {
    return {
      userTagList: [],
      pushData: {}
    }
  },
  methods: {
    /** 获取所有用户标签 */
    async req_getUserTagListAll() {
      const { code, result, message } = await this.$moe_api.USER_API.getUserTagListAll();
      if (code === 200) {
        this.userTagList = result.list.map(({ name, id }) => {
          return {
            label: name,
            value: id
          }
        })
      } else {
        this.$moe_msg.error(message);
      }
    },
    getPushDetail() {
      let id = this.$route.query.id;
      if (id) {
        this.$moe_api.PUSH_API.getPushDetail({ id }).then((data) => {
          if (data.code === 200) {
            this.pushData = data.result;

            if (this.pushData.url) {
              this.pushData.url = this.pushData.url.replace(`${this.$moe_data.appDefaultUrl}`, '');

              if (this.pushData.url.indexOf('?') > -1) {
                this.pushData.urlId = this.$moe_formatter.getUrlParams(this.pushData.url).id;
                this.pushData.url = this.pushData.url.split('?')[0];
              }
            }
            if (this.pushData.sendTarget === 'SPECIFY_USER' && this.pushData.sendTargetData) {
              this.pushData.sendTargetData = this.pushData.sendTargetData.split(',').map((id) => {
                return {
                  id
                }
              })
            }
            if (this.pushData.sendExcludeData) {
              this.pushData.sendExcludeData = this.pushData.sendExcludeData.split(',').map((id) => {
                return {
                  id
                }
              })
            }
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      }
    },
  },
  mounted() {
    this.req_getUserTagListAll();
    this.getPushDetail();
  }
}
</script>

<style lang="scss"></style>